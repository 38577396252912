<template>
  <q-layout :data-route="$route.name" view="hHh lpR fFf">
    <q-header reveal class="bg-primary text-white" height-hint="98">
      <q-toolbar>
        <q-toolbar-title class="justify-start">
          <router-link to="/">
            <div class="logo-head flex flex-center">
              <img height="36" src="./assets/pense-bete_logo-rouge_horizontal.svg">
            </div>
          </router-link>
        </q-toolbar-title>
        <q-tabs align="left" class="desktop" v-model="tab" @update:model-value="update_tab">
          <q-route-tab to="/" name="accueil" label="Accueil"/>

          <q-tab name="permis" class="q-tab-dropdown">
            <q-btn-dropdown name="permis" auto-close stretch flat label="Les permis">
              <q-list>
                <q-item clickable @click="tab = 'permis'" to="/permis/general">
                  <q-item-section>Permis général et NAC</q-item-section>
                </q-item>
                <q-item clickable @click="tab = 'permis'" to="/permis/chien">
                  <q-item-section>Chien</q-item-section>
                </q-item>
                <q-item clickable @click="tab = 'permis'" to="/permis/chat">
                  <q-item-section>Chat</q-item-section>
                </q-item>
                <q-item clickable @click="tab = 'permis'" to="/permis/hamster">
                  <q-item-section>Hamster</q-item-section>
                </q-item>
                <q-item clickable @click="tab = 'permis'" to="/permis/poisson-rouge">
                  <q-item-section>Poisson rouge</q-item-section>
                </q-item>
                <q-item clickable @click="tab = 'permis'" to="/permis/lapin">
                  <q-item-section>Lapin</q-item-section>
                </q-item>
                <q-item clickable @click="tab = 'permis'" to="/permis/cheval">
                  <q-item-section>Cheval</q-item-section>
                </q-item>
                <q-item clickable @click="tab = 'permis'" to="/permis/poule">
                  <q-item-section>Poule</q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </q-tab>

          <q-tab name="pense-bete-tv" class="q-tab-dropdown">
            <q-btn-dropdown name="pense-bete-tv" auto-close stretch flat label="PENSE-BÊTE TV">
              <q-list>
                <q-item :active="is_tv('intro')" name="intro" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#intro">
                  <q-item-section>Videothèque</q-item-section>
                </q-item>
                <q-item :active="is_tv('likes')" name="ecole" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#likes">
                  <q-item-section>Tu likes ou pas ?</q-item-section>
                </q-item>
                <q-item :active="is_tv('ecole')" name="ecole" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#ecole">
                  <q-item-section>Pense Bête à l'école !</q-item-section>
                </q-item>
                <q-item :active="is_tv('general')" name="general" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#general">
                  <q-item-section>Permis général et NAC</q-item-section>
                </q-item>
                <q-item :active="is_tv('chien')" name="chien" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#chien">
                  <q-item-section>Chien</q-item-section>
                </q-item>
                <q-item :active="is_tv('chat')" name="chat" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#chat">
                  <q-item-section>Chat</q-item-section>
                </q-item>
                <q-item :active="is_tv('hamster')" name="hamster" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#hamster">
                  <q-item-section>Hamster</q-item-section>
                </q-item>
                <q-item :active="is_tv('poisson-rouge')" name="poisson-rouge" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#poisson-rouge">
                  <q-item-section>Poisson rouge</q-item-section>
                </q-item>
                <q-item :active="is_tv('lapin')" name="lapin" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#lapin">
                  <q-item-section>Lapin</q-item-section>
                </q-item>
                <q-item :active="is_tv('cheval')" name="cheval" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#cheval">
                  <q-item-section>Cheval</q-item-section>
                </q-item>
                <q-item :active="is_tv('poule')" name="poule" clickable @click="tab = 'pense-bete-tv'"
                    to="/pense-bete-tv/#poule">
                  <q-item-section>Poule</q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </q-tab>

          <!-- <q-tab name="espace-pedagogique" class="q-tab-dropdown">
            <q-btn-dropdown name="espace-pedagogique" auto-close stretch flat label="ESPACE PÉDAGOGIQUE">
              <q-list>
                <q-item name="intro" clickable @click="tab = 'espace-pedagogique'" target="_blank" to="/pdf/Dossier-Pédagogique.pdf">
                  <q-item-section>Dossier Pédagogique</q-item-section>
                </q-item>
                <q-item name="intro" clickable @click="tab = 'espace-pedagogique'" target="_blank" to="/pdf/Fiche-Chien.pdf">
                  <q-item-section>Fiche Chien</q-item-section>
                </q-item>
                <q-item name="intro" clickable @click="tab = 'espace-pedagogique'" target="_blank" to="/pdf/Fiche-Chat.pdf">
                  <q-item-section>Fiche Chat</q-item-section>
                </q-item>
                <q-item name="intro" clickable @click="tab = 'espace-pedagogique'" target="_blank" to="/pdf/Fiche-Hamster.pdf">
                  <q-item-section>Fiche Hamster</q-item-section>
                </q-item>
                <q-item name="intro" clickable @click="tab = 'espace-pedagogique'" target="_blank" to="/pdf/Fiche-Poisson-rouge.pdf">
                  <q-item-section>Fiche Poisson rouge</q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </q-tab> -->
          <q-route-tab name="espace-pedagogique" to="/espace-pedagogique" label="ESPACE PÉDAGOGIQUE"/>
          <q-route-tab name="a-propos" to="/a-propos" label="A propos"/>
          <q-route-tab name="contactez-nous" to="/contactez-nous" label="CONTACTEZ-NOUS"/>

        </q-tabs>

        <q-btn dense flat round icon="menu" @click="toggleRightDrawer" class="mobile"/>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="rightDrawerOpen" side="right" overlay behavior="mobile">
      <swiper
          ref="slide_menu"
          class="swiper-menu"
          :initialSlide="0"
          :allowTouchMove="true"
          :slides-per-view="1"
          :space-between="1"
          direction="horizontal"
      >
        <swiper-slide>
          <q-list bordered separator>
            <q-item clickable v-ripple
                v-for="(menuitem, index) in menu"
                :key="index"
                @click="m_menu(menuitem)"
                :active="is_active(menuitem)">
              <q-item-section>
                <q-item-label>{{ menuitem.title }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </swiper-slide>

        <swiper-slide>
          <q-list bordered separator>

            <q-item clickable v-ripple
                v-for="(menuitem, index) in m_menu_current_subs"
                :key="index"
                @click="m_menu(menuitem)"
                :active="is_active(menuitem)">
              <q-item-section>
                <q-item-label>{{ menuitem.title }}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item class="bg-primary text-white text-center" clickable v-ripple @click="m_menu_back()">
              <q-item-section>
                <q-item-label>&lt; retour</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </swiper-slide>
      </swiper>
    </q-drawer>

    <q-page-container>
      <router-view/>
    </q-page-container>

    <footer class="flex row justify-between">
      <section>
        <div class="footer-cols container flex row justify-between items-strech">
          <div class="logo-footer"><img height="80" src="./assets/images/PB-logo-jaune.png"></div>
          <div>
            <h3>Pense-bête</h3>
            <p>
              <router-link to="/permis/general">Permis général et NAC</router-link>
              <br>
              <router-link to="/permis/chien">Chien</router-link>
              <br>
              <router-link to="/permis/chat">Chat</router-link>
              <br>
              <router-link to="/permis/hamster">Hamster</router-link>
              <br>
              <router-link to="/permis/poisson-rouge">Poisson rouge</router-link>
              <br>
              <router-link to="/permis/lapin">Lapin</router-link>
              <br>
              <router-link to="/permis/cheval">Cheval</router-link>
              <br>
              <router-link to="/permis/poule">Poule</router-link>
            </p>
          </div>
          <div>
            <h3>Vidéothèque</h3>
            <p>
              <router-link to="/pense-bete-tv/#likes">Tu likes ou pas ?</router-link>
              <br>
              <router-link to="/pense-bete-tv/#ecole">Pense Bête à l'école !</router-link>
              <br>
              <router-link to="/pense-bete-tv/#general">Permis général et NAC</router-link>
              <br>
              <router-link to="/pense-bete-tv/#chien">Chien</router-link>
              <br>
              <router-link to="/pense-bete-tv/#chat">Chat</router-link>
              <br>
              <router-link to="/pense-bete-tv/#hamster">Hamster</router-link>
              <br>
              <router-link to="/pense-bete-tv/#poisson-rouge">Poisson rouge</router-link>
              <br>
              <router-link to="/pense-bete-tv/#lapin">Lapin</router-link>
              <br>
              <router-link to="/pense-bete-tv/#cheval">Cheval</router-link>
              <br>
              <router-link to="/pense-bete-tv/#poule">Poule</router-link>
            </p>
          </div>
          <div>
            <h3>Suivez-nous</h3>
            <p>
              <span class="networks">
                <a href="https://www.facebook.com/profile.php?id=100089766876567" target="_blank"><img
                    src="./assets/images/icons8-facebook-30.png"></a>
                <a href="https://www.instagram.com/asblpensebete/?hl=fr" target="_blank"><img
                    src="./assets/images/icons8-instagram-30.png"></a>
                <a href="https://www.youtube.com/@ASBL_Pense-bete" target="_blank"><img
                    src="./assets/images/icons8-youtube-30.png"></a>
              </span><br>
              <router-link to="/contactez-nous">Contactez-nous</router-link>
              <br>
              <router-link to="/contactez-nous">Donnez votre avis !</router-link>
            </p>
          </div>
        </div>
      </section>
      <section class="footer-copyright flex flex-center text-center">
        <div>
          <!-- <router-link to="/contactez-nous" style="color: #fdda63">Donnez votre avis !</router-link><br> -->
          <img src="./assets/images/logo-wallonie.png" height="48" style="margin-bottom: 5px;">
          <br>
          © 2022 Région Wallonne, tous droits réservés •
          <router-link to="/contactez-nous">Contact</router-link>
          •
          <router-link to="mentions-legales">Mentions légales</router-link>
        </div>
      </section>
    </footer>

    <div id="pagetop" class="" v-show="scY > 300" @click="toTop">
      <img src="./assets/images/haut-page.png" width="48">
    </div>

    <div class="q-pa-md q-gutter-sm">
      <q-btn label="Click Me" color="primary" @click="surveyPop = true"/>

      <q-dialog v-model="surveyPop" seamless position="bottom">
        <q-card style="width: 350px">
          <q-card-section class="bg-primary text-white">
            <h6 class="q-ma-none">Comment avez-vous connu Pense-bête ?</h6>
          </q-card-section>
          <q-card-section>
            <form action="" class="column no-wrap">
              <q-radio
                  v-model="survey"
                  val="1"
                  label="Par les réseaux sociaux"
              />
              <q-radio
                  v-model="survey"
                  val="2"
                  label="Par le bouche-à-oreille"
              />
              <q-radio
                  v-model="survey"
                  val="3"
                  label="Lors d'une animation ou d'un salon"
              />
              <q-radio
                  v-model="survey"
                  val="4"
                  label="Par mon vétérinaire"
              />
              <q-radio
                  v-model="survey"
                  val="5"
                  label="Par ma commune (affiche, site internet)"
              />
              <q-radio
                  v-model="survey"
                  val="6"
                  label="Par mon école"
              />
              <q-radio
                  v-model="survey"
                  val="7"
                  label="Par mon refuge"
              />
              <q-radio
                  v-model="survey"
                  val="8"
                  label="Autre"
              />
              <q-input v-if="survey === '8'" v-model="surveyOther" label="Veuillez spécifier"/>
              <div class="flex q-gutter-sm">
                <q-btn class="q-mt-sm col-grow" label="Envoyer" color="primary" @click="answerSurvey"/>
                <q-btn class="q-mt-sm" label="X" color="primary" @click="closeSurvey"/>
              </div>
            </form>

          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </q-layout>
</template>

<script>
import {ref} from 'vue'

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import {useQuasar} from 'quasar'

export default {
  data() {
    return {
      q: useQuasar(),
      surveyPop: true,
      survey: -1,
      surveyOther: '',
      scTimer: 0,
      scY: 0,
      tab: 'accueil',
      menu: [
        {
          title: 'ACCUEIL',
          to: '/',
        },
        {
          title: 'LES PERMIS',
          children: [
            {
              title: 'Permis général',
              to: '/permis/general'
            },
            {
              title: 'Chien',
              to: '/permis/chien'
            },
            {
              title: 'Chat',
              to: '/permis/chat'
            }
            ,
            {
              title: 'Hamster',
              to: '/permis/hamster'
            },
            {
              title: 'Poisson rouge',
              to: '/permis/poisson-rouge'
            },
            {
              title: 'Lapin',
              to: '/permis/lapin'
            },
            {
              title: 'Cheval',
              to: '/permis/cheval'
            },
            {
              title: 'Poule',
              to: '/permis/poule'
            }
          ]
        },
        {
          title: 'PENSE-BÊTE TV',
          children: [
            {
              title: 'Videothèque',
              to: '/pense-bete-tv#intro'
            },
            {
              title: 'Chien',
              to: '/pense-bete-tv#chien'
            },
            {
              title: 'Chat',
              to: '/pense-bete-tv#chat'
            },
            {
              title: 'Hamster',
              to: '/pense-bete-tv#hamster'
            },
            {
              title: 'Poisson rouge',
              to: '/pense-bete-tv#poisson-rouge'
            },
            {
              title: 'Lapin',
              to: '/pense-bete-tv#lapin'
            },
            {
              title: 'Cheval',
              to: '/pense-bete-tv#cheval'
            },
            {
              title: 'Poule',
              to: '/pense-bete-tv#poule'
            },
            {
              title: 'NAC',
              to: '/pense-bete-tv#general'
            },

          ]
        },
        /*{
          title: 'ESPACE PÉDAGOGIQUE',
          children: [
            {
              title: 'Dossier Pédagogique',
              to: '/pdf/Dossier-Pédagogique.pdf',
              target: '_blank'
            },
            {
              title: 'Fiche Chien',
              to: '/pdf/Fiche-Chien.pdf',
              target: '_blank'
            },
            {
              title: 'Fiche Chat',
              to: '/pdf/Fiche-Chat.pdf',
              target: '_blank'
            },
            {
              title: 'Fiche Hamster',
              to: '/pdf/Fiche-Hamster.pdf',
              target: '_blank'
            },
            {
              title: 'Fiche Poisson rouge',
              to: '/pdf/Fiche-Poisson-rouge.pdf',
              target: '_blank'
            },
          ]
        },*/
        {
          title: 'ESPACE PÉDAGOGIQUE',
          to: '/espace-pedagogique'
        },
        {
          title: 'A PROPOS',
          to: '/a-propos'
        },
        {
          title: 'CONTACTEZ-NOUS',
          to: '/contactez-nous'
        },
      ],
      m_menu_current_subs: []
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const rightDrawerOpen = ref(false)

    return {
      rightDrawerOpen,
      toggleRightDrawer() {
        rightDrawerOpen.value = !rightDrawerOpen.value
      }
    }
  },
  beforeMount() {
    this.surveyPop = !this.q.cookies.has('survey')
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.$gtag.pageview(this.$route)
  },
  methods: {
    answerSurvey() {
      const formData = new FormData()
      formData.append('survey', this.survey)
      formData.append('other', this.surveyOther)
      fetch('/handleSurvey.php', {
        method: 'POST',
        body: formData
      })
      this.q.cookies.set('survey', 1, {expires: 30})
      this.surveyPop = false
    },
    closeSurvey() {
      this.q.cookies.set('survey', 0, {expires: 3})
      this.surveyPop = false
    },
    update_tab() {
      this.tab = this.$route.name
    },
    m_menu(menuitem) {
      if (menuitem.to) {
        if (menuitem.target != '_blank') {
          this.$router.push(menuitem.to)
        } else {
          window.open(menuitem.to, "_blank");
        }

      } else {
        this.m_menu_current_subs = menuitem.children
        this.$refs.slide_menu.$el.swiper.slideTo(1, 250)
      }
    },
    m_menu_back() {
      this.$refs.slide_menu.$el.swiper.slideTo(0, 250)
    },
    is_tv(name) {
      if ('#' + name == window.location.hash) {
        return true
      } else {
        return false
      }
    },
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    is_active(menuitem) {

      let child_active = false

      if (menuitem['children']) {
        menuitem['children'].forEach((el) => {
          if (el['to'] == this.$route.path
              ||
              el['to'] == this.$route.path + window.location.hash
          ) {
            child_active = true
          }
        })
      }

      if (menuitem['to'] == this.$route.path
          ||
          menuitem['to'] == this.$route.path + window.location.hash
          || child_active) {

        return true
      } else {
        return false
      }
    }
  },
  watch: {
    '$route.params.slug'() {
      this.$gtag.pageview(this.$route)
      this.m_menu_back();
      this.update_tab();
    },
    '$route.path'() {
      this.$gtag.pageview(this.$route)
      this.m_menu_back()
    }
  }
}
</script>

<style lang="scss">
#pagetop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 9999;
}

.networks img {
  height: 24px;
  margin-top: 5px;
}

.q-dialog__inner.flex {
  justify-content: right;
}
</style>
